<template>
  <div class="useTemplateList">
    <BaseDialog
      title="使用模板"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="900px"
      top="10%"
    >
      <div class="consumables-search">
        <div class="serach">
          <el-form
            :inline="true"
            :model="tableSearch"
            ref="searchForm"
            class="demo-form-inline w-240"
          >
            <el-input
              v-model="tableSearch.search"
              class="hospital"
              placeholder="搜索"
            >
              <template #suffix>
                <i
                  style="cursor: pointer"
                  @click="getDataList"
                  class="fa fa-search"
                ></i>
              </template>
            </el-input>
          </el-form>
        </div>
        <div class="table" ref="tableBoxRef">
          <el-table
            v-loading="dataListLoading"
            :data="tableData"
            class="table-ellipsis"
            size="small"
            border
          >
            <el-table-column prop="name" label="模板名称" />
            <el-table-column prop="desc" label="备注" />
            <el-table-column prop="uid" label="创建人" />
            <el-table-column prop="created_time" label="创建日期" width="160" />
            <el-table-column fixed="right" label="操作" width="80">
              <template #default="scope">
                <el-button
                  type="text"
                  size="small"
                  :loading="saveLoad"
                  @click="useTemp(scope.row)"
                >
                  <i class="txIons use"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-box">
            <el-pagination
              :currentPage="pageData.currentPage"
              :page-size="pageData.pageSize"
              :page-sizes="[10, 20, 50, 100]"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.totalSum"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </BaseDialog>
  </div>
</template>

<script setup>
import { reactive, ref, defineExpose, defineProps, defineEmits } from 'vue'
import service from '@/utils/request'
import { ElMessage } from 'element-plus'
import BaseDialog from '@/components/Dialog/index.vue'

defineProps({
  dictList: {
    type: Object,
  },
})
const tableSearch = reactive({
  search: '',
})
const visible = ref(false)
const tableData = ref([])
const dataListLoading = ref(false)
const pageData = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const use_week = ref(0)

const initData = (item) => {
  visible.value = true
  use_week.value = item
  handleSearch()
}

const handleSearch = () => {
  pageData.currentPage = 1
  getDataList()
}
const handleSizeChange = (val) => {
  pageData.pageSize = val
  getDataList()
}
const handleCurrentChange = (val) => {
  pageData.currentPage = val
  getDataList()
}
const getDataList = async () => {
  tableSearch.current = pageData.currentPage
  tableSearch.size = pageData.pageSize
  dataListLoading.value = true
  let res = await service.post('/api/schedul_tml/list', tableSearch)
  dataListLoading.value = false
  if (res.code === 0) {
    tableData.value = res.data.data
    pageData.totalSum = res.data.total
  }
}

const saveLoad = ref(false)
const em = defineEmits(['refreshDataList'])
const useTemp = async (item) => {
  saveLoad.value = true
  let res = await service.post('/api/schedul_tml/use_tml', {
    id: item.id,
    use_week: use_week.value,
  })
  saveLoad.value = false
  if (res.code === 0) {
    ElMessage.success(res.msg)
    visible.value = false
    em('refreshDataList')
  }
}

defineExpose({
  initData,
})
</script>

<style scoped lang="scss">
.consumables-search {
  margin-top: 16px;
}
.w-240 .el-input {
  width: 240px;
}
</style>
